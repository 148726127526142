export enum CustomPageId {
	Flights = 'flights',
	Flight = 'flight',
	Home = 'home',
	ShopDineRelax = 'shop-dine-relax',
	Map = 'map',
	Park = 'park',
	ParkBookingNew = 'park-booking-new',
	ParkBookingConfirmation = 'park-booking-confirmation',
	Security = 'security',
	Shuttles = 'shuttles',
	RemoteShuttles = 'remote-shuttles',
	ExpressShuttles = 'express-shuttles',
	TerminalLinkShuttles = 'terminal-link-shuttles',
	ShuttleStop = 'shuttle-stop',
	ShuttleStopMap = 'shuttle-stop-map',
	SignIn = 'sign-in',
	SignUp = 'sign-up',
	ResetPassword = 'reset-password',
	Account = 'account',
	PersonalDetails = 'personal-details',
	Vehicles = 'your-vehicles',
	ParkingReservations = 'parking-reservations',
	ReservationDetail = 'reservation-details',
	RetrieveBooking = 'retrieve-booking',
	ExtendBooking = 'extend-booking',
	SecurityPreferences = 'security-preferences',
	CreditCards = 'credit-cards',
	OneDFW = 'onedfw',
}
